import { CommonState } from '@primeit/components-vue';

CommonState.registerModule('triage', {
	actions: {
		async SEND_WEBSOCKET_MESSAGE(context, params) {
			return new Promise((resolve, reject) => {
				(async () => {
          let ws = context.state.ws;
          if (!(ws && ws.readyState === WebSocket.OPEN)) {
            await context.dispatch('START_WEBSOCKET');
            ws = context.state.ws;
          }
          ws.send(JSON.stringify(params));
          resolve(true);
				})();
			});
		},
		async START_WEBSOCKET(context, payload) {
			try {
				const websocket = new WebSocket(
					process.env.VUE_APP_ENDPOINT_WS_TRIAGE,
					sessionStorage.getItem('x-auth-room-participant')
				);

				websocket.addEventListener('message', (ev) => {
					const message = JSON.parse(ev.data);
					console.debug('💬 WS Mensagem:', message);

          switch(message.event) {
            case 'diagnostic':
              context.commit('setDiagnostic', {
                chat: message.data.chat,
                hypotheses: message.data.diagnostic.hypotheses
              });
              context.commit('setReceivedEvent', true);
              break;
            case 'error':
              console.debug('❌ WS Mensagem Error:', message.data.error);
              break;
          };
				});

				return new Promise((resolve, reject) => {
					websocket.addEventListener('open', () => {
						context.commit('setWS', websocket);
						context.commit('setConnected', true);
						console.debug('🚀 WS conexão aberta.');
						resolve(true);
					});

					websocket.addEventListener('close', () => {
						context.commit('setWS', null);
						context.commit('setConnected', false);
						console.debug('❌ WS conexão fechada.');
						reject(false);
					});
				});
			} catch (e) {
				console.error('[ERROR WS]:', e);
			}
		},
	},
	getters: {
		connected: (state) => state.connected,
    diagnostic: (state) => state.diagnostic,
    receivedEvent: (state) => state.receivedEvent,
		ws: (state) => state.ws,
	},
	mutations: {
		setConnected: (state, payload) => state.connected = payload,
    setDiagnostic: (state, payload) => state.diagnostic = payload,
    setReceivedEvent: (state, payload) => state.receivedEvent = payload,
		setWS: (state, payload) => state.ws = payload,
	},
	namespaced: true,
  state: {
		connected: false,
    diagnostic: { chat: [], hypotheses: [] },
    receivedEvent: false,
		ws: null,
	},
});